<template>
    <div class="tags" v-if="showTags">
        <el-tag
          :key="i"
          v-for="(tag, i) in tagsList"
          closable
          :type="tag.type"
          :disable-transitions="false"
          @click="handleClick(tag, i)"
          @close="handleClose(i)">
            {{tag.Name}}
        </el-tag>
    </div>
</template>

<script>

export default {
    data() {
        return {
            showTags: true,
        }
    },
    computed: {
        menuList: function () {
            return this.$store.state.layout.menuList.length > 0 ? this.$store.state.layout.menuList : sessionStorage.getItem('menuList') ? JSON.parse(sessionStorage.getItem('menuList')) : []
        },
        tagsList: function () {
            return this.$store.state.layout.tagsList.length > 0 ? this.$store.state.layout.tagsList : sessionStorage.getItem('tagsList') ? JSON.parse(sessionStorage.getItem('tagsList')) : []
        },
    },
    mounted() {
    },
    watch: {
        $route: {
            handler: 'handleActive',
            deep: true
        },
    },
    methods: {
        handleClose(index) {
            if (this.tagsList[index].Url === '/Product') sessionStorage.removeItem('editProduct')
            this.tagsList.splice(index, 1);
            this.tagsList[this.tagsList.length - 1].type = '';
            this.$store.dispatch('setTagsList', this.tagsList);
            if (this.tagsList[this.tagsList.length - 1].Url !== this.$route.path) this.$router.push(this.tagsList[this.tagsList.length - 1].Url);
        },
        handleClick(data, index) {
            // console.log('handleClick')
            this.tagsList.forEach((v, i) => {
                if (index !== i) v.type = 'info'
            });
            this.tagsList[index].type = '';
            this.$store.dispatch('setTagsList', this.tagsList);
            this.$router.push(data.Url);
        },
        handleActive() {
            this.tagsList.forEach(v => {
                v.type = v.Url == this.$route.path ? '' : 'info';
            });
            this.$store.dispatch('setTagsList', this.tagsList);
        },
    },
};
</script>

