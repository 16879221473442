<template>
    <el-header>
      <div class="header-left">
        <!--
        <div class="collapse-btn" @click="collapseChange">
          <i v-if="!isCollapse" class="el-icon-s-fold"></i>
          <i v-else class="el-icon-s-unfold"></i>
        </div>-->
        <div class="logo">健康商城管理系统</div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" active-text-color="#409EFF" mode="horizontal" @select="handleSelect">
          <el-menu-item v-for="(v, i) in navList" :key="i" :index="v.Url">{{v.Name}}</el-menu-item>
        </el-menu>
      </div>
      <div class="header-right">
        <!-- 消息中心 -->
        <!--
        <div class="btn-bell">
            <el-tooltip effect="dark" :content="message?`有${message}条未读消息`:`消息中心`" placement="bottom">
                <router-link to="/_cloud_basic/Tabs">
                    <i class="el-icon-bell"></i>
                </router-link>
            </el-tooltip>
            <span class="btn-bell-badge" v-if="message"></span>
        </div>-->
        <!-- 用户头像 -->
        <el-avatar :size="35" :src="circleUrl" />
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" @command="handleCommand">
            <span class="el-dropdown-link">
              {{username}}
              <i class="el-icon-caret-bottom"></i>
             </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
</template>
<script>
export default {
  data() {
    return {
      isCollapse: false,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      username: '',
      activeIndex: '',
    }
  },
  computed: {
    menuList: function () {
      return this.$store.state.layout.menuList.length > 0 ? this.$store.state.layout.menuList : sessionStorage.getItem('menuList') ? JSON.parse(sessionStorage.getItem('menuList')) : []
    },
    tagsList: function () {
      return this.$store.state.layout.tagsList.length > 0 ? this.$store.state.layout.tagsList : sessionStorage.getItem('tagsList') ? JSON.parse(sessionStorage.getItem('tagsList')) : []
    },
    navList: function () {
      let arr = this.menuList.map(v => {
        let obj = {
          Name: v.Name,
          Url: v.Url
        };
        return obj
      });
      return arr
    },
  },
  mounted() {
    this.username = sessionStorage.getItem('UserName');
    this.handleActive();
  },
  watch: {
    $route: {
      handler: 'handleActive',
      deep: true
    },
  },
  methods: {
    collapseChange() {
      this.isCollapse = !this.isCollapse;
    },
    handleActive() {
      this.activeIndex = this.$route.path;
    },
    handleCommand(command) {
      if (command === 'loginOut') {
        sessionStorage.clear();
        this.$store.dispatch('setTagsList', []);
        this.$store.dispatch('setMenuList',[]);
        this.$router.push('/login');
        location.reload();
      }
    },
    handleSelect(key, keyPath) {
      let item, fetchItem = (arr) => {
        if (arr.length > 0){
          arr.forEach(v => {
            if (v.Node && v.Node.length > 0) {
              fetchItem(v.Node)
            } else {
              if (v.Url === key) {
                item = v
              }
            }
          })
        }
      };
      if (key !== this.$route.path) this.$router.push(key);
      let arr = this.tagsList.filter(v => { return v.Url === key });
      if (arr.length === 0) {
        if (this.tagsList.length > 0) this.tagsList.forEach(v => {v.type = 'info'});
        fetchItem(this.menuList);
        this.tagsList.push(Object.assign({}, item, {type: ''}));
        this.$store.dispatch('setTagsList', this.tagsList);
      }
    }
  },
};
</script>
