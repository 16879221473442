import router from './router'
const _import = require('./router/_import_' + process.env.NODE_ENV);
import Layout from '@/views/Layout/Index.vue'

let getRouter;

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  if (to.path === '/login') {
    next();
  } else {
    const token = sessionStorage.getItem('Token');
    if (!token) {
      next('/login');
    } else {
      if (!getRouter) {
        routerGo().then(() => {
          next({ ...to, replace: true })
        });
      } else {
        next();
      }
    }
  }
});

let fetchRouters = [];
function routerGo() {
  return new Promise(resolve => {
    getRouter = getObjArr('menuList');
    filterRouter(getRouter);
    fetchRouters.forEach(v => {
      router.addRoute(v);
    });
    // router.addRoute(getRouter);
    resolve();
  })
}

function saveObjArr(name, data) {
  sessionStorage.setItem(name, JSON.stringify(data))
}

function getObjArr(name) {
  return JSON.parse(sessionStorage.getItem(name));
}

function filterRouter(routerArr) {
  routerArr.forEach(route => {
    if (route.Node && route.Node.length) {
      filterRouter(route.Node)
    } else {
      let obj = {
        component: Layout,
        path: route.Url,
        meta: {
          title: route.Name,
        },
        children: [],
      };
      obj.children[0] = {
        component: _import(route.Component),
        path: route.Url,
        meta: {
          title: route.Name,
        }
      };
      fetchRouters.push(obj);
    }
  });
}
