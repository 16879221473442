import Vue from 'vue'
import Vuex from 'vuex'
import layout from './moduels/layout'
import table from './moduels/table'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    layout,
    table,
  }
})

