<template>
    <el-container>
        <v-header />
        <el-container>
            <v-sidebar />
            <el-main style="padding: 0;">
                <v-tags></v-tags>
                <div class="main">
                    <router-view/>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import vHeader from "./components/Header.vue";
import vSidebar from "./components/Sidebar.vue";
import vTags from "./components/Tags.vue";
export default {
    components: {
        vHeader,
        vSidebar,
        vTags,
    },
};
</script>
