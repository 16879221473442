<template>
    <el-menu
      class="el-menu-vertical-demo"
      :default-active="activeIndex"
      :collapse="isCollapse"
      @select="handleSelect">
        <template v-for="(item, i) in menuList">
            <el-submenu v-if="item.Node" :index="item.Url" :key="i">
                <template slot="title">{{ item.Name }}</template>
                <template v-for="(subItem, subIndex) in item.Node">
                    <el-submenu v-if="subItem.Node" :index="subItem.Url" :key="subIndex">
                        <template slot="title">{{ subItem.Name }}</template>
                        <el-menu-item v-for="(threeItem, threeIndex) in subItem.Node" :key="threeIndex" :index="threeItem.Url">
                            <span slot="title">{{ threeItem.Name }}</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :index="subItem.Url" :key="subIndex"><span slot="title">{{ subItem.Name }}</span></el-menu-item>
                </template>
            </el-submenu>
            <el-menu-item v-else  :key="i" :index="item.Url">
                <span slot="title">{{item.Name}}</span>
            </el-menu-item>
        </template>
    </el-menu>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            isCollapse: false,
            activeIndex: '0',
        }
    },
    computed: {
        menuList: function () {
            return this.$store.state.layout.menuList.length > 0 ? this.$store.state.layout.menuList : sessionStorage.getItem('menuList') ? JSON.parse(sessionStorage.getItem('menuList')) : []
        },
        tagsList: function () {
            return this.$store.state.layout.tagsList.length > 0 ? this.$store.state.layout.tagsList : sessionStorage.getItem('tagsList') ? JSON.parse(sessionStorage.getItem('tagsList')) : []
        },
    },
    mounted() {
        // console.log(this.menuList)
        this.handleActive();
        this.handleSelect(this.activeIndex);
    },
    watch: {
        $route: {
            handler: 'handleActive',
            deep: true
        },
    },
    methods: {
        handleActive() {
            this.activeIndex = this.$route.path;
        },
        handleSelect(key) {
            let item, fetchItem = (arr) => {
                if (arr.length > 0){
                    arr.forEach(v => {
                        if (v.Node && v.Node.length > 0) {
                            fetchItem(v.Node)
                        } else {
                            if (v.Url === key) {
                                item = v
                            }
                        }
                    })
                }
            };
            if (key !== this.$route.path) this.$router.push(key);
            if (key === '/Product') {
                this.$store.dispatch('setIsEditProduct', false);
                sessionStorage.removeItem('editProduct');
            }
            let arr = this.tagsList.filter(v => { return v.Url === key });
            if (arr.length === 0) {
                if (this.tagsList.length > 0) this.tagsList.forEach(v => {v.type = 'info'});
                fetchItem(this.menuList);
                this.tagsList.push(Object.assign({}, item, {type: ''}));
                this.$store.dispatch('setTagsList', this.tagsList);
            }
        },
    },
};
</script>

