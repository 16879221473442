const table = {
  state: {
    categoryOptions: [],
    regionOptions: [],
    isEditProduct: false,
    tenantOptions: [],
  },
  mutations: {
    setCategoryOptions(state, data) {
      state.categoryOptions = data;
      sessionStorage.setItem('categoryOptions', JSON.stringify(data));
    },
    setRegionOptions(state, data) {
      state.regionOptions = data;
      sessionStorage.setItem('regionOptions', JSON.stringify(data));
    },
    setIsEditProduct(state, data) {
      state.isEditProduct = data;
    },
    setTenantOptions(state, data) {
      state.tenantOptions = data;
    },
  },
  actions: {
    setCategoryOptions: ({ commit }, data) => {
      commit('setCategoryOptions', data)
    },
    setRegionOptions: ({ commit }, data) => {
      commit('setRegionOptions', data)
    },
    setIsEditProduct: ({ commit }, data) => {
      commit('setIsEditProduct', data)
    },
    setTenantOptions: ({ commit }, data) => {
      commit('setTenantOptions', data)
    },
  },
}
export default table;
