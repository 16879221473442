const layout = {
  state: {
    collapse: false,
    userInfo: {},
    menuList: [],
    tagsList: [],
  },
  mutations: {
    // 侧边栏折叠
    handleCollapse(state, data) {
      state.collapse = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
      sessionStorage.setItem('Token', data.Token);
      sessionStorage.setItem('UserName', data.Account);
      sessionStorage.setItem('RoleCode', data.Role.Code);
      sessionStorage.setItem('TenantId', data.TenantId);
      sessionStorage.setItem('TenantName', data.Tenant.Name);
    },
    setMenuList(state, data) {
      state.menuList = data;
      if (data.length > 0) {
        sessionStorage.setItem('menuList', JSON.stringify(data));
      } else {
        sessionStorage.removeItem('menuList')
      }
    },
    setTagsList(state, data) {
      state.tagsList = data;
      if (data.length > 0) {
        sessionStorage.setItem('tagsList', JSON.stringify(data));
      } else {
        sessionStorage.removeItem('tagsList')
      }
    },
  },
  actions: {
    handleCollapse: ({ commit }, data) => {
      commit('handleCollapse', data)
    },
    setUserInfo: ({ commit }, data) => {
      commit('setUserInfo', data)
    },
    setMenuList: ({ commit }, data) => {
      commit('setMenuList', data)
    },
    setTagsList: ({ commit }, data) => {
      commit('setTagsList', data)
    },
  },
}
export default layout;
