import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "../views/Layout/Index.vue";

Vue.use(VueRouter)
const role = sessionStorage.getItem('RoleCode');

const routes = [
  {
    path: '/',
    redirect: role === 'tenantAdmin' ? '/dashboardTenant' : '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import( '../views/Login.vue')
  },
  // {
  //   path: "/",
  //   component: Layout,
  //   name: "Home",
  //   children: [
  //     {
  //       path: "/dashboard",
  //       name: "dashboard",
  //       meta: {
  //         title: '控制台'
  //       },
  //       component: () => import ("../views/Dashboard/HomeView.vue")
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
